import {WebGLRenderer, Scene, Clock, Camera, sRGBEncoding} from 'three';
import {EffectComposer} from "three/addons/postprocessing/EffectComposer";
import {RenderPass} from "three/addons/postprocessing/RenderPass";
import {teams} from "@/Parameters";
import {Sleep} from "@/renderEngine/Utils";

let renderer,
    clock,
    sceneTemplate,
    cameraTemplate,
    composer,
    renderPipeline,
    resourcePromise;

export function createRenderer() {
    renderer = new WebGLRenderer({
        powerPreference: "high-performance",
        antialias: true,
        stencil: false
    });
    renderer.setSize(RenderEngineUtils.Width, RenderEngineUtils.Height);
    renderer.setPixelRatio(RenderEngineUtils.PixelRatio);
    renderer.physicallyCorrectLights = true;

    composer = new EffectComposer(renderer);

    clock = new Clock();

    window.addEventListener("resize", resize);
}

export function appendRendererTo(parentElement) {
    parentElement.appendChild(renderer.domElement);
}

function resize() {
    renderer.setSize(RenderEngineUtils.Width, RenderEngineUtils.Height);

    if (cameraTemplate) {
        cameraTemplate.update();
    }

    for (const renderPipelineElement of renderPipeline) {
        renderPipelineElement.update(composer);
    }

    if (sceneTemplate) {
        sceneTemplate.resize();
    }


}

export function setScene(sceneTemplateInstance) {

    sceneTemplate = sceneTemplateInstance;
    sceneTemplate.firstInteraction = true;
}
export async function createScene() {
    let scene = new Scene();

    cameraTemplate = await sceneTemplate.fillScene(scene);
    if (sceneTemplate.error) {
        return sceneTemplate.error;
    }

    cameraTemplate.create();
    sceneTemplate.scene = scene;

    // camera effects
    let tonemapping = sceneTemplate.getTonemapping();
    renderer.toneMapping = tonemapping[0];
    renderer.toneMappingExposure = tonemapping[1];

    renderPipeline = sceneTemplate.getRenderPipeline();

    if (sceneTemplate.allowShadows) {
        renderer.shadowMap.enabled = true;
    }

    for (const renderPipelineElement of renderPipeline) {
        renderPipelineElement.append(composer);
    }

    resourcePromise = sceneTemplate.prepareResources();
}


export async function sendClickEvent(data) {

    await Promise.all([resourcePromise]); // wait for the resource promise
    await sceneTemplate.onClick(sceneTemplate.scene, data);

    sceneTemplate.firstInteraction = false;
}

export async function startRendering() {

    if (!sceneTemplate) {
        console.error("Tried to start rendering without scene. Please call setScene before starting rendering.");
    }
    if (!cameraTemplate) {
        console.error("Tried to start rendering without camera. Please call createScene before starting rendering.");
    }



    resize();
    clock.start();
    render();

    if (teams) {
        await Sleep(1000);
        document.getElementById("showMantraButton").click();
    }
}

function render() {
    requestAnimationFrame(render);

    sceneTemplate.update(sceneTemplate.scene, clock.getDelta());

    composer.render();
}

export class RenderEngineUtils {

    static get Theme() {
        return sceneTemplate.theme;
    }

    static get Scene() {
        return sceneTemplate.scene;
    }
    static get Camera() {
        return cameraTemplate.camera;
    }

    static get AspectRatio() {
        return this.Width / this.Height;
    }

    static get Width() {
        return window.innerWidth;
    }
    static get Height() {
        return window.innerHeight;
    }

    static get PixelRatio() {
        return window.devicePixelRatio * 1.25;
    }

    static get Renderer() {
        return renderer;
    }
}
