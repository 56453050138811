import {FileSceneTemplate} from "@/renderEngine/SceneTemplates/FileSceneTemplate";
import {
    ACESFilmicToneMapping,
    AnimationMixer, CineonToneMapping,
    Color,
    HemisphereLight,
    LoopOnce,
    Matrix4,
    MeshBasicMaterial, MeshPhongMaterial, NoToneMapping,
    Quaternion, ReinhardToneMapping
} from "three";
import {Skydome} from "@/renderEngine/Objects/Skydome";
import {PostProcessingTemplate} from "@/renderEngine/PostProcessingTemplate";
import {MapRange, Noise1D, Sleep} from "@/renderEngine/Utils";
import {Theme} from "@/renderEngine/Theme";
import {SceneTemplate} from "@/renderEngine/SceneTemplates/SceneTemplate";
import {runAnimationsBackwards, runAnimationsForwards, waitForMixer} from "@/renderEngine/SceneTemplates/SceneUtils";
import {SceneBaseProperties} from "@/renderEngine/SceneTemplates/SceneBaseProperties";

export class ChestScene extends FileSceneTemplate {

    get allowShadows() {
        return true;
    }

    get theme() {
        return Theme.Light;
    }

    get name() {
        return "Treasure Chest";
    }

    get visible() {
        return true;
    }

    getTonemapping() {
        return [CineonToneMapping, 2];
    }
    constructor() {
        super("chest");

        super.maxCharactersPerLine = 35;

        this.rotateAnimation = {};
        this.openAnimations = [];
        this.originalLightIntensity = 0;
        this.time = 0;
        this.textMaterial = new MeshBasicMaterial({
            color: 0xFFFFFF
        });
        this.font = {};
    }

    async prepareResources() {
        this.font = await SceneTemplate.fontLoader.loadAsync(SceneBaseProperties.FontFolder + SceneBaseProperties.DefaultFont);
    }

    async fillScene(scene) {
        let camera = await super.fillScene(scene);
        console.log(this);

        const light = this.lights[0];
        light.intensity = 20;
        this.originalLightIntensity = light.intensity;
        light.castShadow = true;

        light.shadow.bias = -.01;

        light.shadow.camera.near = 0.001;
        light.shadow.camera.far = 20;

        this.objects[0].receiveShadow = true;
        this.objects[0].traverse(a => {
            if (!a.isMesh) return;
            a.castShadow = true;
        });
        this.cameraTarget = this.gltf.scene.children.find(a => a.name == "Camera_Target");

        let hemiLightIntensity = .5;
        let skyColorHex = 0x0088FF;
        let groundColor = new Color(0x000000);
        groundColor.multiplyScalar(.5);
        const hemiLight = new HemisphereLight( skyColorHex, groundColor, hemiLightIntensity);
        hemiLight.position.set( 0, 0 , 0 );
        scene.add( hemiLight );

        let skyColor = new Color(skyColorHex);
        skyColor.multiplyScalar(hemiLightIntensity);
        let groundColorSkydome = new Color(groundColor);
        groundColorSkydome.multiplyScalar(hemiLightIntensity);


        // setup animation
        this.mixer = new AnimationMixer(this.gltf.scene);

        for (let i = 0; i < this.animations.length; i++) {
            let action = this.mixer.clipAction(this.animations[i]);
            action.loop = LoopOnce;
            action.clampWhenFinished = true;
            this.openAnimations.push(action);
        }

        return camera;
    }

    update(scene, deltatime) {
        super.update(scene, deltatime);
        this.time += deltatime * 4;

        this.mixer.update(deltatime);

        let noise = Noise1D(this.time);
        let multiply = MapRange(noise, -1, 1, .8, 1);
        this.lights[0].intensity = this.originalLightIntensity * multiply;
    }

    getRenderPipeline() {
        return [
            PostProcessingTemplate.DefaultScene(false),
            PostProcessingTemplate.FXAA()
        ];
    }

    async onClick(scene, data) {
        super.onClick(scene, data);

        if (!super.firstInteraction) {
            runAnimationsBackwards(this.openAnimations);
            await waitForMixer(this.mixer);

            await Sleep(500);
        }

        let textParent = this.gltf.scene.children.find(a => a.name == "Text_Parent");
        textParent.rotation.y = Math.PI;
        let result = await super.handleTextMesh(this.font, 'mantra', data['mantra_text'], this.textMaterial, textParent, 0.05);
        runAnimationsForwards(this.openAnimations);

        await waitForMixer(this.mixer);
    }

}
