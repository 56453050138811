import {SceneTemplate} from "@/renderEngine/SceneTemplates/SceneTemplate";
import {Mesh, PlaneGeometry, ShaderMaterial} from "three";
import {RenderEngineUtils} from "@/renderEngine/RenderEngine";
import {CameraTemplate} from "@/renderEngine/CameraTemplate";

export class ShaderSceneTemplate extends SceneTemplate
{
    static get DefaultVertex() {
        return `
        
            varying vec2 vUV;
            varying vec3 vWorldPosition;
            void main() {
                
                vUV = uv;
                
                vWorldPosition = vec3(modelMatrix * vec4(position, 1.0));                
                gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
            }
        `;
    }

    constructor({ vertex = ShaderSceneTemplate.DefaultVertex, fragment = '', shaderUniforms = {}, customGeometry = false, fillWindow = false} = {}) {
        super();

        if (fragment === '') {
            throw 'Fragment must be defined';
        }

        this.fillWindow = fillWindow;
        this.geometry = new PlaneGeometry(1, 1);
        this.material = new ShaderMaterial({
            vertexShader: vertex,
            fragmentShader: fragment,
            uniforms: shaderUniforms
        });
        this.object = new Mesh(this.geometry, this.material);
        if (fillWindow) {
            this.object.scale.x = RenderEngineUtils.AspectRatio;
        }
    }

    async fillScene(scene) {

        scene.add(this.object);

        return CameraTemplate.OrthographicCamera;
    }

    resize() {
        if (this.fillWindow) {
            this.object.scale.x = RenderEngineUtils.AspectRatio;
        }
    }
}