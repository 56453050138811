import {FontLoader} from "three/addons/loaders/FontLoader";
import {SceneBaseProperties} from "@/renderEngine/SceneTemplates/SceneBaseProperties";
import {BufferAttribute, Group, Mesh} from "three";
import {TextGeometry} from "three/addons/geometries/TextGeometry";

export class SceneTemplate extends SceneBaseProperties {

    static get splitRegex() {
        return /[\s-:]/;
    }

    static get fontLoader() {
        if (SceneTemplate._fontLoader) {
            return SceneTemplate._fontLoader;
        }

        SceneTemplate._fontLoader = new FontLoader();
        return SceneTemplate._fontLoader;
    }

    set maxCharactersPerLine(maxCharactersPerLine) {
        this._maxCharactersPerLine = maxCharactersPerLine;
    }

    constructor(props) {
        super(props);
        this.fonts = {};
        this._maxCharactersPerLine = 32;
    }


    async handleTextMesh (font, type, text, material, parent = undefined, size = 1, heightScale = 0.1, curveSegments = 12) {
        if (typeof font === 'string' || font instanceof String) {
            font = this.fonts[font];
            if (!font) {
                font = await SceneTemplate.fontLoader.loadAsync(font);
            }
        }

        let group;
        let groupId = "group"+type;
        if (!this[groupId]) {
            group = this[groupId] = new Group();
            if (parent) parent.add(group);
        } else group = this[groupId];

        group.clear();

        let i = -1;
        let minPos = 0;
        let lines = this.handleText(text);
        for (let line of lines) {
            i++;
            const geometry = new TextGeometry(line, {
                font: font,
                size: size,
                height: size * heightScale,
                curveSegments: curveSegments
            });
            geometry.center();

            let newPos = (size * 2 * i) - (size * 2 * (lines.length - 1)) / 2;

            let mesh = new Mesh(geometry, material);
            mesh.position.set(0, -newPos, 0);
            group.add(mesh);
        }



        return group;
    }

    async prepareFonts(fontPaths) {
        this.fonts = {};
        for (const fontPath of fontPaths) {
            let font = await SceneTemplate.fontLoader.loadAsync(SceneBaseProperties.FontFolder + fontPath);
            this.fonts[fontPath] = font;
        }
    }

    handleText(text) {
        let lines = [];
        let currentLine = '';
        let currentLineIndex = 0;
        let currentIndex = 0;
        let splitTarget;
        while (splitTarget !== -1) {
            splitTarget = text.substring(currentIndex).search(SceneTemplate.splitRegex);
            let textInsert = text.substring(currentIndex, currentIndex + splitTarget + 1);
            if (splitTarget === -1) textInsert = text.substring(currentIndex);

            currentIndex += splitTarget + 1;
            currentLineIndex += splitTarget + 1;

            if (currentLineIndex >= this._maxCharactersPerLine) {
                currentLineIndex = 0;
                lines.push(currentLine);
                currentLine = textInsert;
                continue;
            }

            currentLine += textInsert;
        }

        lines.push(currentLine);
        return lines;
    }
}