export class Theme {

    get MainColor() {
        return this.mainColor;
    }
    get BackgroundColor() {
        return this.backgroundColor;
    }
    get UnfocusedColor() {
        return this.unfocusedColor;
    }
    get FocusedColor() {
        return this.focusedColor;
    }

    constructor(
        mainColor,
        backgroundColor,
        unfocusedColor,
        focusedColor
    ) {
        this.mainColor = mainColor;
        this.unfocusedColor = unfocusedColor;
        this.focusedColor = focusedColor;
        this.backgroundColor = backgroundColor;
    }

    static get Light() {
        return new Theme(
            'white',
            'rgba(255,255,255,.5)',
            '#303030',
            'black'
        );
    }

    static get Dark() {
        return new Theme(
            'black',
            'rgba(0,0,0,.1)',
            '#707070',
            'black'
        );
    }
}