import {RenderEngineUtils} from "@/renderEngine/RenderEngine";
import {RenderPass} from "three/addons/postprocessing/RenderPass";
import {UnrealBloomPass} from "three/addons/postprocessing/UnrealBloomPass";
import {Vector2} from "three";
import {FXAAShader} from "three/addons/shaders/FXAAShader";
import {ShaderPass} from "three/addons/postprocessing/ShaderPass";
import {GammaCorrectionShader} from "three/addons/shaders/GammaCorrectionShader";
import {AfterimagePass} from "three/addons/postprocessing/AfterimagePass";
import {SobelOperatorShader} from "three/addons/shaders/SobelOperatorShader";


export class PostProcessingTemplate {
    constructor(create, update) {
        this._append = create;
        this._update = update;
    }

    append(composer) {
        this._append(composer);
    }

    update() {
        this._update();
    }

    static DefaultScene(useGamma = false) {
        let gammaShader = new ShaderPass(GammaCorrectionShader);
        let renderPass = new RenderPass(RenderEngineUtils.Scene, RenderEngineUtils.Camera);

        return new PostProcessingTemplate(
            (composer) => {
                composer.addPass(renderPass);
                if (useGamma) composer.addPass(gammaShader);
            }, () => {}
        );
    }
    static Bloom(
        strength = 1.1,
        radius = .4,
        threshold = 0.8

    ) {

        const bloom = new UnrealBloomPass( new Vector2( RenderEngineUtils.Width, RenderEngineUtils.Height ), strength, radius, threshold);
        return new PostProcessingTemplate(
            (composer) => {
                composer.addPass(bloom);
            },
            () => {
                bloom.setSize(RenderEngineUtils.Width, RenderEngineUtils.Height);
            }

        );

    }

    static FXAA() {

        const fxaaPass = new ShaderPass(FXAAShader);
        return new PostProcessingTemplate( (composer) => {
            const pixelRatio = RenderEngineUtils.Renderer.getPixelRatio();

            const resolution = fxaaPass.material.uniforms['resolution'];
            resolution.value.x = 1 / (RenderEngineUtils.Width * pixelRatio);
            resolution.value.y = 1 / (RenderEngineUtils.Height * pixelRatio);
            composer.addPass(fxaaPass);
        }, () => {
            const pixelRatio = RenderEngineUtils.Renderer.getPixelRatio();

            const resolution = fxaaPass.material.uniforms['resolution'];
            resolution.value.x = 1 / (RenderEngineUtils.Width * pixelRatio);
            resolution.value.y = 1 / (RenderEngineUtils.Height * pixelRatio);
        });
    }

    static AfterImage(damp = .94) {
        let pass = new AfterimagePass(damp);
        return new PostProcessingTemplate((composer) => {
            composer.addPass(pass);
        }, () => {
        });
    }

    static SobelOutline() {
        let shaderPass = new ShaderPass(SobelOperatorShader);
        return new PostProcessingTemplate((composer) => {
            const pixelRatio = RenderEngineUtils.Renderer.getPixelRatio();

            const resolution = shaderPass.material.uniforms['resolution'];
            resolution.value.x = RenderEngineUtils.Width * pixelRatio;
            resolution.value.y = RenderEngineUtils.Height * pixelRatio;
            composer.addPass(shaderPass);
        }, () => {
            const pixelRatio = RenderEngineUtils.Renderer.getPixelRatio();
            const resolution = shaderPass.material.uniforms['resolution'];
            resolution.value.x = RenderEngineUtils.Width * pixelRatio;
            resolution.value.y = RenderEngineUtils.Height * pixelRatio;

        });
    }
}