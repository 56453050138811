<template>
  <div>
    <div class="buttonContainer">
      <button id="showMantraButton" @click="fetchAllData" :disabled="disableMantraButton" type="button" class="button">Zeige Mantra</button>
    </div>
    <div class="buttonContainer loadingContainer" v-if="displayLoadingIcon">
      <div class="loadingObject spinner-border">
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {RenderEngineUtils, sendClickEvent} from "@/renderEngine/RenderEngine";
import * as Parameters from '@/Parameters';
import {Sleep} from "@/renderEngine/Utils";

export default {
  methods: {
    fetchAllData: async function () {
      this.disableMantraButton = true;
      this.displayLoadingIcon = true;

      const d = new Date();
      let day = d.getDay();

      const IMAGE_PROBABILITY = 0.5;
      const CHECK_DAY = 3;
      const HEXAGON_SCENE_ID = 1;
      const DEV_UNIT_ID = 2;

      if (
          day === CHECK_DAY &&
          Parameters.scene == HEXAGON_SCENE_ID &&
          Parameters.unit == DEV_UNIT_ID &&
          Math.random() < IMAGE_PROBABILITY
      ) {

        let data = {
          'image' : '/projects/event_it/mantramizer/templates/registration/mantramizer/dist/images/wednesday.png',
          'imageAspect': 680 / 510
         }

         await sendClickEvent(data);
         this.displayLoadingIcon = false;
         await Sleep(1000); // sleep for a second
         this.disableMantraButton = false;
         return
      }

      let response;
      try {
        response = await axios.get('/projects/event_it/mantramizer/sources/lib/php/getMantras.php?mprefix=event_it&pprefix=mantramizer&unit=' + Parameters.unit , {
          action: 'fetchAll'
        });
        response.data.mantra_text = response.data.mantra_text.replaceAll('&#39;', "'");
        response.data.mantra_text = response.data.mantra_text.replaceAll('&#34;', '"');
      } catch (error) {
        console.error(error);
        response = {
          data: {
            mantra_text: "Something went wrong with the HTTP-request",
            mantra_type: "Sorry :("
          }
        };
      }
      this.displayLoadingIcon = false;

      await sendClickEvent(response.data);
      await Sleep(1000); // sleep for a second
      this.disableMantraButton = false;
    }
  },
  data() {
    return {
      disableMantraButton: false,
      displayLoadingIcon: false,
      theme: RenderEngineUtils.Theme
    }
  }
}
</script>

<style>
.buttonContainer {
  position: absolute;
  bottom:30px;
  width: 100%;
  text-align: center;
}
.button {
  margin: auto;
  min-width: 250px;
  max-width: 500px;
  font-size: x-large;
  border: 3px solid beige;
  background: #0a0a0a;
  color: beige;
  padding: 10px 35px;
  border-radius: 5px;
  transition: transform 0.3s ease;
  transform: scale(1);
}

.button:hover {
  border: 3px solid darkslategray;
}
.button:disabled {
  transform: scale(0, .4);
}

.loadingContainer {

  bottom: 40px;
  z-index: 10;

}
.loadingObject {
  color: v-bind(theme['MainColor']);
}
</style>