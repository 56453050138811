import Cookies from 'js-cookie';
import {SceneList} from "@/renderEngine/SceneList";

export let allowedCookies;
export let scene;
export let unit;
export let teams;

// https://stackoverflow.com/questions/5448545/how-to-retrieve-get-parameters-from-javascript
function findGetParameter(parameterName) {
    var result = null,
        tmp = [];
    var items = location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

export function handleGetParameters() {
    allowedCookies = Cookies.get('cookies');

    teams = !!findGetParameter('teams');

    scene = findGetParameter('scene');
    if (!scene) {
        scene = Cookies.get('scene');
    }
    if (!scene) {
        scene = "random";
    }

    unit = findGetParameter('unit');
    if (!unit) {
        unit = Cookies.get('unit');
    }
    if (!unit) {
        unit = 'all';
    }

    setCookies(false);
}


export function setScene(sceneEntry) {
    scene = sceneEntry;

    setCookies();
}
export function setUnit(unitEntry) {
    unit = unitEntry;

    setCookies();
}

let wasAskedForCookies = false;
export function setCookies(allowAsking = true) {
    if (!allowedCookies) {
        if (!allowAsking || wasAskedForCookies) {
            return;
        }

        if (confirm("Dürfen wir Cookies verwenden um die Daten auch über Sessions hinweg zuspeichern?")) {
            allowedCookies = true;
        } else {
            wasAskedForCookies = true;
            return;
        }
    }
    Cookies.set('cookies', 1, {expires: 365});
    Cookies.set('scene', scene, {expires: 365});
    Cookies.set('unit', unit, {expires: 365});
}