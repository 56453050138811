import {Sleep} from "@/renderEngine/Utils";

export async function waitForMixer(mixer, ignoreClips = []) {
    let actions = mixer._actions;

    let done = false;
    while(!done) {
        await Sleep(5);
        done = true;

        for (const action of actions) {
            if (!action.isRunning() || ignoreClips.indexOf(action._clip.name) !== -1) {
                continue;
            }

            done = false;
        }
    }
}

export function runAnimationsForwards(actionArray) {
    actionArray.forEach(a => {
        a.timeScale = 1;
        a.time = 0;

        a.play();
        a.paused = false;
    });
}
export function runAnimationsBackwards(actionArray, timescale = 1.5) {
    actionArray.forEach(a => {
        a.timeScale = -timescale;
        a.time = a._clip.duration;

        a.play();
        a.paused = false;
    });
}