const M = 4294967296, A = 1664525, C = 1;
var Z = Math.floor(Math.random() * M);

export function MapRange(value, fromLow, fromHigh, toLow, toHigh) {
    return (value - fromLow) * (toHigh - toLow) / (fromHigh - fromLow) + toLow;
}

export function Noise1D(x) {
    return Math.sin(2 * x) + Math.sin(Math.PI * x);
}

export function Sleep(delay) {
    return new Promise(resolve => setTimeout(resolve, delay));
}