import {Theme} from "@/renderEngine/Theme";
import {NoToneMapping} from "three";
import {CameraTemplate} from "@/renderEngine/CameraTemplate";
import {PostProcessingTemplate} from "@/renderEngine/PostProcessingTemplate";

export class SceneBaseProperties {
    static get DefaultFont() {
        return 'eventit_regular.json';
    }

    static get FontFolder() {
        return './fonts/';
    }

    get name() {
        return 'default';
    }
    get visible() {
        return false;
    }
    get theme() {
        return Theme.Dark;
    }

    getTonemapping() {
        return [NoToneMapping, 1];
    }
    get allowShadows() {
        return false;
    }
    set firstInteraction(value) {
        this._firstInteraction = value;
    }
    get firstInteraction() {
        return this._firstInteraction;
    }

    constructor() {
    }

    async fillScene(scene) {
        return CameraTemplate.OrthographicCamera;
    }

    async prepareResources() {
    }

    resize() {

    }

    update(scene, deltatime) {

    }

    onClick(scene, data) {

    }

    getRenderPipeline() {
        return [PostProcessingTemplate.DefaultScene()];
    }
}