<template>
  <div id="app">
    <nav class="main-nav">
      <div>
        <div class="nav">
          <a class="navButton" @click="toggleSelection"><i class="fa-solid fa-gear" :class="{navButtonOpen: showUnitSelection}"></i></a>
          <div class="unitDisplay">Unit: {{ selectedUnitName }}</div>
        </div>

        <div class="selectionPanel" id="unitSelection" :class="{closedSelectionPanel: !showUnitSelection}">
          <label for="unitSelect" class="select-label">Unit</label><br>
          <select name="unit" class="select" id="unitSelect" v-model="selectedUnit" @change="handleChangeUnit">
            <option value="all" :selected="selectedUnit === 'all'">
              - Für Alle Units -
            </option>
            <option v-for="unit in unitData" :selected="selectedUnit === unit.objvalueid" :key="unit.objvalueid" :value="unit.objvalueid">
              {{ unit.unitname }}
            </option>
          </select>
          <label for="sceneSelect" class="select-label">Szene</label><br>
          <select @change="handleChangeScene" name="scene" class="select" id="sceneSelect" v-model="selectedScene" aria-label="Scene Selection" >
            <option value="random">
              - Random -
            </option>
            <option v-for="scene in scenes" :selected="selectedScene == scene.index" :key="scene.index" :value="scene.index">
              {{scene.name}}
            </option>
          </select>

          <div v-if="displayReloadScene">
            <button class="btn btn-primary" @click="applyChanges">Apply Scene</button>
          </div>
        </div>
      </div>
    </nav>
    <MantraData/>
  </div>
</template>

<script>
import MantraData from "@/components/mantra/MantraData"
import axios from "axios";
import {SceneList} from "@/renderEngine/SceneList";
import * as Parameters from '@/Parameters';
import {RenderEngineUtils} from "@/renderEngine/RenderEngine";

export default {
  name: 'App',
  components: {
    MantraData,
  },
  methods: {
    async getUnits() {
      let response = await axios.get('/projects/event_it/mantramizer/sources/lib/php/getUnits.php?mprefix=event_it&pprefix=mantramizer', {
        action: 'fetchAll'
      });
      this.unitData = response.data;
      this.selectedUnitName = this.selectedUnit === 'all' ? "Alle Units" : this.unitData.find(a => a.objvalueid == this.selectedUnit).unitname;
    },
    toggleSelection() {
      this.showUnitSelection = !this.showUnitSelection;
    },
    handleChangeUnit() {
      this.selectedUnitName = this.selectedUnit === 'all' ? "Alle Units" : this.unitData.find(a => a.objvalueid == this.selectedUnit).unitname;
      Parameters.setUnit(this.selectedUnit);
    },
    handleChangeScene() {
      this.displayReloadScene = true;
      Parameters.setScene(this.selectedScene);
    },
    applyChanges() {
      location.href = '?scene=' + this.selectedScene + "&unit=" + this.selectedUnit;
    }
  },
  async beforeMount() {
    await this.getUnits();
  },
  data() {
    return {
      scenes: SceneList.getVisibleList(),
      acceptedCookies: Parameters.allowedCookies,
      selectedScene: Parameters.scene,
      selectedUnit: Parameters.unit,
      theme: RenderEngineUtils.Theme,
      unitData: [],
      selectedUnitName: this.selectedUnit === 'all' ? "Alle Units" : '',
      displayReloadScene: false,
      showUnitSelection: false
    }
  }
}
</script>

<style>
#app {
  font-family: 'Event IT';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html {
  height: 100%;
  overflow:hidden;
}

body {
  border: 0; margin: 0; padding: 0;
  height: 100%;
}

#background {
  z-index: -1;
  position: absolute;
  top:0;
  left:0;
}

.loading {

  position: absolute;
  top: 50%;
  left: 50%;
  width: 3rem;
  height: 3rem;
  margin: -1.5rem 0 0 -1.5rem;
}

.logo {
  align-self: center;
  color: #fff;
  font-weight: bold;
}

.closedSelectionPanel {
  transform: scale(.5, 0);
}

.nav {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;

  padding:10px;
}

.navButton {
  color: v-bind(theme['MainColor']);
  transition: transform 3s ease;
  cursor: pointer;
}

.navButtonOpen {
  transform: rotateZ(90deg);
}

.main-nav {
  display: flex;
  justify-content: space-between;
}

.selectionPanel {
  transform-origin: top left;
  background-color: v-bind(theme['BackgroundColor']);
  position: absolute;
  top:43px;
  padding: 5px 10px;
  transition: transform .6s cubic-bezier(.165,.84,.44,1);
}

#unitSelection {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
}

ul.sidebar-panel-nav {
  list-style-type: none;
}

ul.sidebar-panel-nav > li > a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  display: block;
  padding-bottom: 0.5em;
}
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.select {
  margin-bottom: 20px;
  width: 90%;
  background: none;
  border-bottom-width: 3px;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  color: v-bind(theme["UnfocusedColor"]);
  border-bottom-color: #0f0086;
  font-size: 15pt;

  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}
.select > option {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-size: 12pt;
  color: initial;
}

.select:hover, .select:focus {
  color: v-bind(theme["FocusedColor"]);
  border-bottom-color: #3829a2;
}

.select-label {
  color: v-bind(theme["FocusedColor"]);
  font-size: 12pt;
  text-align: start;
  margin-top: 5px;
}

.unitDisplay {
  margin-left: 20px;
  cursor: default;
  color: v-bind(theme["MainColor"]);
}
</style>
