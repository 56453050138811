import Vue from 'vue';
import App from './App.vue';
import {createRenderer, setScene, startRendering, appendRendererTo, createScene} from "./renderEngine/RenderEngine";
import * as Parameters from "@/Parameters";
import {SceneList} from "@/renderEngine/SceneList";

Parameters.handleGetParameters();

let backgroundElem = document.createElement('div');
backgroundElem.id = "background";
document.body.appendChild(backgroundElem);

let loading = document.createElement('div');
loading.className = "loading spinner-border";
document.body.appendChild(loading);
initRenderer();

Vue.config.productionTip = false;
new Vue({
  render: h => h(App),
}).$mount('#app');

function initRenderer() {
  let scene = SceneList.getScene(Parameters.scene);

  if (!scene) {
    loading.innerHTML = "<p>The scene wasn't found... :(</p>";
  }

  createRenderer();
  appendRendererTo(backgroundElem);

  setScene(scene);
  createScene().then(a => {
    loading.remove();
    startRendering();
  }).catch(a => {
    console.error(a);
    loading.innerHTML = "<p>The scene caused an error. :(<br><br>"+a+"</p>";
  });
}
