import {SceneTemplate} from "@/renderEngine/SceneTemplates/SceneTemplate";
import {GLTFLoader} from "three/addons/loaders/GLTFLoader";
import {RenderEngineUtils} from "@/renderEngine/RenderEngine";
import {CameraTemplate} from "@/renderEngine/CameraTemplate";

export class FileSceneTemplate extends SceneTemplate {

    constructor(pathToFile) {
        super();
        this.path = 'scenes/' + pathToFile + '.glb';
        this.gltf = {};
        this.objects = [];
        this.animations = [];
        this.lights = [];
    }

    async fillScene(scene) {
        const loader = new GLTFLoader();
        this.gltf = await loader.loadAsync(this.path);

        scene.add(this.gltf.scene);

        this.gltf.scene.children.forEach(a => {

            if (a.isGroup || a.isMesh) {
                this.adjustObject(a);

                a.isAnimated = a.name.startsWith('#');
                this.objects.push(a);
            }

            if (a.isLight) {
                this.lights.push(a);
            }
        });

        this.animations.push(...this.gltf.animations);
        let camera = this.gltf.cameras[0];
        return new CameraTemplate(() => {
            return camera;
        }, camera.isPerspectiveCamera ?
            (camera) => {
                camera.aspect = RenderEngineUtils.AspectRatio;
            } : (camera) => {});

    }

    adjustObject(object) {

    }
}