import {RenderEngineUtils} from "./RenderEngine";
import { PerspectiveCamera, OrthographicCamera } from 'three';

export class CameraTemplate {

    constructor(create, update) {
        this._create = create;
        this._update = update;
    }

    get camera() {
        return this._camera;
    }

    create() {
        this._camera = this._create();
    }

    update() {
        this._update(this._camera);
        this._camera.updateProjectionMatrix();
    }

    static get PerspectiveCamera() {
        return new CameraTemplate(
            () => {
                let perspectiveCamera = new PerspectiveCamera(90, RenderEngineUtils.AspectRatio);
                perspectiveCamera.position.z = 5;
                return perspectiveCamera;
            },
            (camera) => {
                camera.aspect = RenderEngineUtils.AspectRatio;
            }
        );
    }

    static get OrthographicCamera() {
        return new CameraTemplate(
            () => {
                let orthographicCamera = new OrthographicCamera(RenderEngineUtils.AspectRatio / -2, RenderEngineUtils.AspectRatio / 2, 0.5, -0.5, 0.01, 50);
                orthographicCamera.position.z = 5;
                return orthographicCamera;
            },
            (camera) => {
                camera.left = RenderEngineUtils.AspectRatio / -2;
                camera.right = RenderEngineUtils.AspectRatio / 2;
            }
        );
    }
}

