import {SceneTemplate} from "@/renderEngine/SceneTemplates/SceneTemplate";
import {CameraTemplate} from "@/renderEngine/CameraTemplate";
import {
    AnimationClip, AnimationMixer,
    BoxGeometry,
    Color,
    InterpolateSmooth,
    Mesh,
    MeshBasicMaterial,
    VectorKeyframeTrack, Group, AdditiveAnimationBlendMode, LoopOnce, InterpolateLinear
} from 'three';
import {TextGeometry} from "three/addons/geometries/TextGeometry";
import {FontLoader} from "three/addons/loaders/FontLoader";
import {PostProcessingTemplate} from "@/renderEngine/PostProcessingTemplate";
import {runAnimationsBackwards, waitForMixer} from "@/renderEngine/SceneTemplates/SceneUtils";
import {SceneBaseProperties} from "@/renderEngine/SceneTemplates/SceneBaseProperties";

export class BasicScene extends SceneTemplate {

    get name() {
        return "Basic";
    }

    get visible() {
        return true;
    }

    constructor() {
        super();

        this.textMaterial = new MeshBasicMaterial({color: 0x000000});

        const times = [0, 1];
        this.cubeAnimation = new AnimationClip(
            'remove', -1, [
                new VectorKeyframeTrack(
                    'cube.scale',
                    times,
                    [
                        1,1,1,0,0,0
                    ],
                    InterpolateLinear
                )
            ]
        );

        const textScaleAnimation = new VectorKeyframeTrack('text.scale', times, [
           0,0,0,0.5,0.5,0.5
        ], InterpolateLinear);

        this.textAnimation = new AnimationClip(
            'show', -1,
            [
                textScaleAnimation
            ]
        );
    }

    async prepareResources() {
        super.prepareResources();
        await super.prepareFonts([SceneBaseProperties.DefaultFont]);
    }

    async fillScene(scene) {
        scene.background = new Color(0xDBEFF4);
        scene.scale.x = scene.scale.y = scene.scale.z = 0.5;

        this.obj = new Mesh(new BoxGeometry(1,1,1), new MeshBasicMaterial( { color: 0x000000 } ));
        this.obj.name = "cube";
        scene.add(this.obj);

        this.text = new Group();
        this.text.scale.set(0,0,0);
        this.text.name = "text";
        scene.add(this.text);

        this.currentMixer = new AnimationMixer(scene);
        this.cubeAnimation = this.currentMixer.clipAction(this.cubeAnimation);
        this.cubeAnimation.loop = LoopOnce;
        this.cubeAnimation.clampWhenFinished = true;
        this.textAnimation = this.currentMixer.clipAction(this.textAnimation);
        this.textAnimation.loop = LoopOnce;
        this.textAnimation.clampWhenFinished = true;

        return CameraTemplate.OrthographicCamera;
    }

    update(scene, deltatime) {
        this.currentMixer.update(deltatime);
        this.obj.rotation.x += 1 * deltatime;
        this.obj.rotation.y += 1 * deltatime;

    }

    async onClick(scene, data) {

        if (!super.firstInteraction) {
            runAnimationsBackwards([this.textAnimation]);
            await waitForMixer(this.currentMixer);
        }

        await super.handleTextMesh(SceneBaseProperties.DefaultFont, 'mantra', data["mantra_text"], this.textMaterial, this.text, .15);


        this.textAnimation.timeScale = 1;
        this.textAnimation.time = 0;
        this.textAnimation.paused = false;

        this.cubeAnimation.play();
        this.currentMixer.addEventListener('finished', mixerEvent);

        function mixerEvent(e) {
            if (e.action._clip.name == 'remove') {
                e.action._mixer._actions[1].play();
            }
        }

        await waitForMixer(this.currentMixer);
        this.currentMixer.removeEventListener('finished', mixerEvent);
    }

    getRenderPipeline() {
        return [
            PostProcessingTemplate.DefaultScene(),
            PostProcessingTemplate.AfterImage(.65)
        ];
    }
}