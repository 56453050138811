import {ShaderSceneTemplate} from "@/renderEngine/SceneTemplates/ShaderSceneTemplate";

export class ShaderText extends ShaderSceneTemplate {
    constructor() {
        super({
            fragment: `
                  #define PI 3.141592653589793
                  
                  varying vec3 vWorldPosition;
                  
                  uniform float time;
                  uniform float speed;
                  
                  vec2 rotatedUv(vec2 uv, float radians) {
                    float sinus = sin(radians);
                    float cosinus = cos(radians);
                
                    return vec2(
                      uv.x * cosinus + uv.y * sinus,
                      uv.x * -sinus + uv.y * cosinus
                    );
                  }
                  
                  void main() {
                
                    vec2 uv = vWorldPosition.xy * 5.0;
                    float rotationCircle = pow(1. / 2., 1.- length(uv));
                    gl_FragColor = vec4(rotationCircle, 0,0, 1);
                    vec2 newUvs = rotatedUv(uv, (rotationCircle) / PI * 10. + time);
                    float add = newUvs.x + newUvs.y;
                    float result = (1. - rotationCircle) * add;
                    gl_FragColor = vec4(result, result, result, 1);
                  }
            `,
            shaderUniforms: {
                time: { value: 0.0 },
                speed: { value: .1 }
            },
            fillWindow: true
        });

    }

    update(scene, deltatime) {

        this.material.uniforms.time.value += deltatime;
    }

}