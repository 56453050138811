import {FileSceneTemplate} from "@/renderEngine/SceneTemplates/FileSceneTemplate";
import {MapRange} from "@/renderEngine/Utils";
import {
    AnimationMixer,
    Color,
    Fog,
    HemisphereLight,
    LoopOnce, Mesh,
    MeshBasicMaterial, PlaneGeometry, TextureLoader
} from "three";
import {PostProcessingTemplate} from "@/renderEngine/PostProcessingTemplate";
import {Theme} from "@/renderEngine/Theme";
import {runAnimationsBackwards, runAnimationsForwards, waitForMixer} from "@/renderEngine/SceneTemplates/SceneUtils";
import {SceneBaseProperties} from "@/renderEngine/SceneTemplates/SceneBaseProperties";

export class HexagonScene extends FileSceneTemplate {

    get visible() {
        return true;
    }

    get name() {
        return "Hexagon";
    }

    get theme() {
        return Theme.Light;
    }

    constructor() {
        super('hexagon');
        this.time = 0;
        this.mixer = {};
        this.animationClips = [];
        this.mantraTextObject = {};
        this.typeTextObject = {};
        this.dontAllowAnimatedObject = false;
        this.textMaterial = new MeshBasicMaterial({color: 0xffffff});
    }

    async prepareResources() {
        super.prepareResources();
        await super.prepareFonts([SceneBaseProperties.DefaultFont]);
    }

    async fillScene(scene) {
        let cameraTemplate = await super.fillScene(scene);

        scene.background = new Color(0x202020);
        scene.fog = new Fog(0x202020, 15, 30);

        let hemisphereLight = new HemisphereLight(0x202020, 0x000000, 1);
        hemisphereLight.color.setHSL(0.6, 1, 0.6);
        hemisphereLight.groundColor.setHSL(0.095, 1, 0.75);
        scene.add(hemisphereLight);

        for (const obj of this.gltf.scene.children) {
            if (!obj.isLight) continue;
            obj.intensity /= 4;
        }

        this.mixer = new AnimationMixer(this.gltf.scene);
        for (const animation of this.animations) {
            let clip = this.mixer.clipAction(animation);
            clip.loop = LoopOnce;
            clip.clampWhenFinished = true;
            this.animationClips.push(clip);
        }

        this.originalPos = this.objects.map(a => a.position);
        this.mantraTextObject = this.gltf.scene.children.find(a => a.name === "Mantra_Text");
        this.typeTextObject = this.gltf.scene.children.find(a => a.name === "Type_Text");

        this.objects[0].children[1].material.emissive = new Color(0xFFFF70);
        this.objects[0].children[1].material.emissiveIntensity = 50;
        return cameraTemplate;
    }

    update(scene, deltatime) {
        super.update(scene, deltatime);
        this.time += deltatime * 2;
        for (let i = 0; i < this.originalPos.length; i++) {
            let object = this.objects[i];
            if (this.dontAllowAnimatedObject && object.isAnimated) continue;

            let originalPos = this.originalPos[i];
            let value = originalPos.x + originalPos.z;
            value += this.time;

            value *= .5;
            let addZ = Math.sin(value);
            addZ = MapRange(addZ, -1, 1, 0, 1);
            addZ *= .25;
            object.position.y = addZ;
        }

        this.mixer.update(deltatime);
    }

    async onClick(scene, data) {

        if (!super.firstInteraction) {
            runAnimationsBackwards(this.animationClips);
            await waitForMixer(this.mixer);

            for (let child of this.mantraTextObject.children) {
                this.mantraTextObject.remove(child);
            }
            // this is a bad way to ensure the text is rendered next time. Too Bad!
            this["groupmantra"] = undefined;
        }

        if ('image' in data) {
            const dataurl = data.image;
            this.imageLoader = this.imageLoader ?? new TextureLoader();
            const texture = await this.imageLoader.loadAsync(dataurl);
            const material = new MeshBasicMaterial({ map: texture, color: new Color(0xcccccc) });

            const scale = 2;
            let width = scale;
            if ('imageAspect' in data) {
                width = data.imageAspect * scale;
            }
            const geometry = new PlaneGeometry(width,scale);
            const mesh = new Mesh(geometry, material);

            this.mantraTextObject.add(mesh);
        } else {
            await super.handleTextMesh(SceneBaseProperties.DefaultFont, 'mantra', data["mantra_text"], this.textMaterial, this.mantraTextObject, .15);
        }

        runAnimationsForwards(this.animationClips);
        this.dontAllowAnimatedObject = true;

        await waitForMixer(this.mixer);
    }

    getRenderPipeline() {
        return [
            PostProcessingTemplate.DefaultScene(false),
            PostProcessingTemplate.Bloom(1.5, 1, 0.875),
            PostProcessingTemplate.FXAA()
        ];
    }
}