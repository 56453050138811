import {BasicScene} from "@/scenes/1-basic";
import {HexagonScene} from "@/scenes/2-hexagonScene";
import {ShaderText} from "@/scenes/3-shaderTest";
import {ChestScene} from "@/scenes/4-chestScene";

export class SceneList {

    get list() {
        return this._list;
    }

    constructor() {
        this._list = [
            new BasicScene(),
            new HexagonScene(),
            new ShaderText(),
            new ChestScene()
        ];
    }

    static get instance() {
        if (SceneList._instance) {
            return SceneList._instance;
        }
        SceneList._instance = new SceneList();
        return SceneList._instance;
    }

    static getVisibleList() {
        let result = [];
        let list = SceneList.instance.list;
        for(let i = 0; i < list.length; i++) {
            let scene = list[i];
            if (!scene.visible) continue;

            result.push({
                index: i,
                name: scene.name
            });
        }
        return result;
    }

    static getScene(identifier) {

        let index = identifier;
        if (identifier === "random") {
            let scenes = this.getVisibleList();
            let random = Math.floor(Math.random() * (scenes.length));
            index = scenes[random].index;
        }

        return SceneList.instance.list[index];
    }
}